import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRestaurantDetailStream, streamOrderById, streamPaymentScreen } from '../../../services/FirebaseFirestoreService'
import './index.css'
import { convertHexToRGBA, numberWithCommas } from '../../../helper/utils'
import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

const PaymentScreenMobile = () => {
    const params: any = useParams()
    const resId = params.resId
    const [orderId, setOrderId] = useState<string | null>()
    const [order, setOrder] = useState<any>()
    const [restaurant, setRestaurant] = useState()
    useEffect(() => {
        streamPaymentScreen(resId, (data: any) => {
            setOrderId(data.data().orderDocId)
        })
    }, [])

    useEffect(() => {
        getRestaurantDetailStream(resId, (data: any) => {
            setRestaurant(data.data())
            if (data.data().screen) {
                const { textColor, backgroundColor } = data.data().screen
                document.documentElement.style.setProperty('--primary-color', textColor)
                document.documentElement.style.setProperty('--secondary-color', backgroundColor)
                document.documentElement.style.setProperty('--primary-color-50', convertHexToRGBA(textColor, 0.5))
                document.documentElement.style.setProperty('--primary-color-80', convertHexToRGBA(textColor, 0.8))
            } else {
                document.documentElement.style.setProperty('--primary-color', '#8A6025')
                document.documentElement.style.setProperty('--secondary-color', '#fafafb')
                document.documentElement.style.setProperty('--primary-color-50', 'rgba(138, 96, 37, 0.5)')
                document.documentElement.style.setProperty('--primary-color-80', 'rgba(138, 96, 37, 0.8)')
            }
        })
    }, [resId])

    useEffect(() => {
        if (orderId) {
            streamOrderById(resId, orderId, (data: any) => {
                setOrder(data.data())
            })
        } else {
            setOrder(null)
        }
    }, [orderId])

    return <div>{order && orderId ? <OrderScreen data={order} restaurant={restaurant} /> : <Home restaurant={restaurant} />}</div>
}
export default PaymentScreenMobile

interface OrderProps {
    data: any
    restaurant: any
}

interface HomeProps {
    restaurant: any
}

const Home: FC<HomeProps> = ({ restaurant }) => {
    const [index, setIndex] = React.useState(0)
    const timeoutRef = React.useRef(null)

    useEffect(() => {
        if (restaurant?.adsPath.length == 1) return
        resetTimeout()
        // @ts-ignore
        timeoutRef.current = setTimeout(() => setIndex((prevIndex) => (prevIndex === restaurant?.adsPath.length - 1 ? 0 : prevIndex + 1)), 3000)
        return () => {
            resetTimeout()
        }
    }, [index, restaurant?.adsPath])

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
    }

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
            }}
        >
            <div className="pl-2 pr-2 d-flex justify-content-center align-items-center" style={{ flexDirection: 'column', backgroundColor: 'black', height: '100%' }}>
                <p className='text-large' style={{ fontWeight: 'bolder', color: 'white' }}>{restaurant?. name}</p>
                <p className='text-large' style={{ fontWeight: 'bolder', color: 'white' }}>いらっしゃいませ</p>
                <p className='text-medium' style={{ color: 'white' }}>ATPOS 格安セルフオーダーシステム</p>
            </div>
        </div>
    )
}

const OrderScreen: FC<OrderProps> = ({ data, restaurant }) => {
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [customerPaid, setCustomerPaid] = useState(0)
    const [totalPreTax, setTotalPretax] = useState(0)
    const [discount, setDiscount] = useState<any>()

    useEffect(() => {
        let total = 0
        let totalPreTax = 0
        data.productsPaid?.forEach((item: any) => {
            if ((item.options !== undefined && item.options.length > 0) || (item.toppings !== undefined && item.toppings.length > 0)) {
                let priceProduct = item.pricePostTax
                let pricePreTax = item.price

                item.options.forEach((option: any) => {
                    const priceOption = option.type.find((obj: any) => obj.isSelect === true)?.price

                    priceProduct += Number(priceOption)
                    pricePreTax += Math.round(Number(priceOption) / 1.1)
                })

                item.toppings?.forEach((topping: any) => {
                    topping.type.forEach((type: any) => {
                        if (type.quantity > 0) {
                            priceProduct += type.pricePostTax * type.quantity
                            pricePreTax += type.price * type.quantity
                        }
                    })
                })

                total = total + priceProduct * item.quantity
                totalPreTax = totalPreTax + pricePreTax * item.quantity
            } else {
                total = total + item.pricePostTax * item.quantity
                totalPreTax = totalPreTax + item.price * item.quantity
            }
        })
        const _tax = total - totalPreTax
        let discount = 0
        if (data?.voucher?.type === 'VALUE') {
            discount = data.voucher.value
        } else if (data?.voucher?.type === 'PERCENT') {
            discount = (data.voucher.value / 100) * total
        }

        setDiscount(Math.round(discount))

        setTax(_tax)
        setTotal(total - Math.round(discount))
        setTotalPretax(totalPreTax)
        setCustomerPaid(data?.customer_paid)
    }, [data])

    if (data.status === 'inprocess') {
        return (
            <div
                className="pl-2 pr-2 d-flex justify-content-center align-items-center"
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'black',
                    flexDirection: 'column',
                }}
            >
                <Row style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-center" span={24}>
                        <p className='text-medium' style={{ color: 'white' }}>{restaurant?. name}</p>
                    </Col>
                </Row>
                <Row className="pt-4 pb-4" style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-start text-large" style={{ color: 'white' }} span={8}>
                        合計
                    </Col>
                    <Col span={16}>
                        <Row>
                            <Col className='text-large' span={24} style={{ color: 'white' }}>
                                {numberWithCommas(total)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                お預かり
                            </Col>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                {/* {numberWithCommas(totalPreTax)} */}0 円
                            </Col>
                        </Row>
                        {data?.voucher?.type && data?.voucher?.value !== 0 && (
                            <Row>
                                <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                    割引
                                </Col>
                                <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                    {numberWithCommas(discount)}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                おつり
                            </Col>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                {/* {numberWithCommas(tax)} */}0 円
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-center" span={24}>
                        <p className='text-small' style={{ color: 'white' }}>ATPOS 格安セルフオーダーシステム</p>
                    </Col>
                </Row>
            </div>
        )
    }

    if (data.status === 'complete') {
        return (
            <div
                className="pl-2 pr-2 d-flex justify-content-center align-items-center"
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'black',
                    flexDirection: 'column',
                }}
            >
                <Row style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-center" span={24}>
                        <p className='text-medium' style={{ color: 'white' }}>{restaurant?. name}</p>
                    </Col>
                </Row>
                <Row className="pt-4 pb-4" style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-start text-large" style={{ color: 'white' }} span={8}>
                        合計
                    </Col>
                    <Col span={16}>
                        <Row>
                            <Col className='text-large' span={24} style={{ color: 'white' }}>
                                {numberWithCommas(total)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                お預かり
                            </Col>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                {numberWithCommas(customerPaid)}
                            </Col>
                        </Row>
                        {data?.voucher?.type && data?.voucher?.value !== 0 && (
                            <Row>
                                <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                    割引
                                </Col>
                                <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                    {numberWithCommas(discount)}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                おつり
                            </Col>
                            <Col className='text-medium' style={{ color: 'white' }} span={12}>
                                {numberWithCommas(customerPaid - total)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col className="d-flex justify-content-center align-items-center" span={24}>
                        <p className='text-small' style={{ color: 'white' }}>ATPOS 格安セルフオーダーシステム</p>
                    </Col>
                </Row>
            </div>
        )
    }

    return <Home restaurant={restaurant} />
}
